import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import Checkbox from '@material-ui/core/Checkbox'
import { Button, IconArrowLeft, Notification } from 'shared'
import './ReviewChange.scss'
import { COMPANY_PHONE_NUMBER } from '../contact-us-modal/ContactUsModal'
import { TermsModalProps } from './SwitchContentfulModal.termsModal.tel'
import { Account } from '../../redux/accounts/accounts.model'
import { Product, productHasStrategies } from '../../common/product-helper'
import { InvestmentOptionType } from '../compare-investment-options/InvestmentOption'
import { isGlidePath } from '../../utils/strategy'
import { UserData } from '../../redux/user/user.model'
import {
  getProductFundUpdatesPagePath,
  getShortName,
} from '../../utils/investmentOption'
import TermsModalTel from './SwitchContentfulModal.termsModal.tel'
import TermsModalApex from './SwitchContentfulModal.termsModal.apex'
import {
  isInvestmentFundsAccount,
  isKiwiSaverOneAccount,
  isKiwiSaverPlanAccount,
  isKiwiSaverTwoAccount,
  isManagedFundProductAccount,
} from '../../common/accounts-helper'
import TermsModalFFTWO from './SwitchContentfulModal.termsModal.fftwo'

const Row = ({ title, value }: { title: string; value: string }) => (
  <div className="allocation-row color-midnight text-regular my-md">
    <span>
      <strong>{title}</strong>
    </span>
    <span className="ml-xl">{value}</span>
  </div>
)

const renderAllocation = (
  shortName: string,
  allocationPercentage: number = 0
) => (
  <div className="allocation-row color-midnight" key={shortName}>
    <span>
      <strong>{shortName}</strong>
    </span>
    <span className="ml-2xl">{allocationPercentage}%</span>
  </div>
)

type ReviewSwitchProps = {
  account: Account
  user: UserData
  newInvestmentOption: InvestmentOptionType
  isApexProductAccount: boolean
  product: Product
  onCancel: () => void
  onSubmit: () => void
} & Omit<TermsModalProps, 'onClose'>

const ReviewChange = (props: ReviewSwitchProps) => {
  const {
    account,
    user,
    product,
    newInvestmentOption,
    isApexProductAccount,
    product: { slug },
    onCancel,
    onSubmit,
    ...termsModalProps
  } = props
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)
  const form = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })
  const {
    register,
    formState: { errors },
  } = form

  const productHasPDS = !!product.productDisclosureStatement
  const shouldShowAllocationLabel = !isInvestmentFundsAccount(account)

  const renderInvestmentOptionsDetails = () => {
    if (isGlidePath(newInvestmentOption.strategy)) {
      return <Row title="GlidePath" value="100%" />
    }

    if (newInvestmentOption.isCustom) {
      return (
        <div className="funds-distribution">
          {!!newInvestmentOption.allocations?.length && (
            <div>
              <strong className="color-text-light">Current Balance</strong>
              {newInvestmentOption.allocations.map((allocation) =>
                renderAllocation(
                  allocation.fund.shortName,
                  allocation.allocationPercentage
                )
              )}
            </div>
          )}

          {!!newInvestmentOption.futureAllocations?.length && (
            <div>
              {!!shouldShowAllocationLabel && (
                <strong className="color-text-light">Future Investments</strong>
              )}
              {newInvestmentOption.futureAllocations?.map((allocation) =>
                renderAllocation(
                  allocation.fund.shortName,
                  allocation.allocationPercentage
                )
              )}
            </div>
          )}
        </div>
      )
    }

    if (!!newInvestmentOption.strategy) {
      return newInvestmentOption.strategy.strategyFundAllocations.map(
        (allocation) =>
          renderAllocation(
            allocation.fund.shortName,
            allocation.allocationPercentage
          )
      )
    }

    return renderAllocation(newInvestmentOption.shortName, 100)
  }

  const switchToLabel = useMemo(() => {
    if (newInvestmentOption.shortName) {
      const shortName = getShortName(newInvestmentOption)
      // The lines below can probably be deleted and just leave return `Switch to ${shortName}`
      if (!isApexProductAccount && !/strategy/i.test(shortName)) {
        return `Switch to ${shortName} strategy`
      }
      return `Change to ${shortName}`
    }
    return 'Switch'
  }, [newInvestmentOption, isApexProductAccount])

  const productVariant = productHasStrategies(slug) ? 'strategy' : 'fund'

  const renderApexOrTelSwitchProcessDetail = () => {
    if (isApexProductAccount) {
      return (
        <>
          <p className="text-small">
            Please allow up to three business days for your{' '}
            {isManagedFundProductAccount(account) ? 'strategy' : 'switch'}{' '}
            change request to be processed.
          </p>
          <p className="text-small">
            Before completing your change, please note:
          </p>
          <ol>
            <li>Once requested, this change cannot be stopped or altered.</li>
            <li>
              You can only make one {productVariant} change request at a time.
              If you'd like to make another {productVariant} change request,
              you'll need to wait until this one has been completed.
            </li>
          </ol>
        </>
      )
    }
    return (
      <>
        <p className="text-small">
          Please allow a minimum of two business days for your switch to be
          processed however before completing your switch, please note:
        </p>
        <ol>
          <li>
            If you have any transactions currently pending i.e. contributions or
            withdrawal, your switch will begin processing once this is complete.
          </li>
          <li>
            If you have submitted a tax rate update, your switch will be
            processed once your PIR has been updated.
          </li>
          <li>
            If none of the above applies, any requests received after 3pm may
            not be processed until the next working day. Your{' '}
            {props.account.productDisplayName} account is an investment and a
            change to how your funds are invested will take a minimum of 2
            working days to be completed. The unit price is calculated after
            both local and overseas markets have closed, so an investment switch
            processed on Monday, for example, can only be completed once all
            markets have closed - which would normally fall on the Wednesday.
          </li>
        </ol>
      </>
    )
  }

  const renderTermsModal = () => {
    if (isKiwiSaverTwoAccount(account, false)) {
      return (
        <TermsModalFFTWO onClose={() => setShowTermsAndConditions(false)} />
      )
    }
    const hasRebalanceStrategy =
      isKiwiSaverOneAccount(props.account, props.account.isOwner) ||
      isManagedFundProductAccount(props.account)
    if (isApexProductAccount) {
      return (
        <TermsModalApex
          account={account}
          onClose={() => setShowTermsAndConditions(false)}
          showRebalanceTerm={hasRebalanceStrategy}
          {...termsModalProps}
        />
      )
    }
    return (
      <TermsModalTel
        account={account}
        onClose={() => setShowTermsAndConditions(false)}
        showRebalanceTerm={hasRebalanceStrategy}
        {...termsModalProps}
      />
    )
  }

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="review-your-strategy-change">
          {productHasPDS ? (
            <div className="pds-disclaimer">
              <h6>
                {props.account.productDisplayName} Product Disclosure Statement
                (PDS)
              </h6>
              <p>
                This document gives you important information about this
                investment to help you decide where you want to invest. There is
                other useful information about this offer on{' '}
                <a
                  href="https://disclose-register.companiesoffice.govt.nz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  disclose-register.companiesoffice.govt.nz
                </a>
                . Fisher Funds Management Limited has prepared this document in
                accordance with the Financial Markets Conduct Act 2013. If you
                have any questions regarding your investment or want advice,
                please contact us.
              </p>

              <p>
                <a
                  href={product.productDisclosureStatement.file.file.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download the Product Disclosure Statement (PDS)
                </a>
              </p>
            </div>
          ) : (
            <Notification>
              <p className="text-small">
                See our{' '}
                <a
                  href={getProductFundUpdatesPagePath(slug)}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  fund updates
                </a>{' '}
                on our website for the latest information about the investment
                options.
              </p>
            </Notification>
          )}
          <h6 className="color-midnight">Review your details</h6>
          <div className="review-your-strategy-change client-details">
            <div>
              <p className="text-small">First name</p>
              <p>{user.firstName}</p>
            </div>
            <div>
              <p className="text-small">Surname</p>
              <p>{user.surname}</p>
            </div>
            <div>
              <p className="text-small">
                {account.productDisplayName} account number
              </p>
              <p>{account.accountID}</p>
            </div>
            <div>
              <p className="text-small">Email address</p>
              <p>{user.email}</p>
            </div>
            <div>
              <p className="text-small">Phone number</p>
              <p>
                {user.mobilePhoneNumber ||
                  user.homePhoneNumber ||
                  user.businessPhoneNumber}
              </p>
            </div>
          </div>
          <hr />
          <h6 className="color-midnight my-sm">
            Changing your {productHasStrategies(slug) ? 'strategy' : 'fund(s)'}
          </h6>
          <p>
            I want to change my current balance and
            {isApexProductAccount &&
            !isKiwiSaverTwoAccount(account, false) &&
            !(
              isManagedFundProductAccount(account) &&
              newInvestmentOption.isCustom
            ) &&
            !isKiwiSaverPlanAccount(account)
              ? ''
              : '/or'}{' '}
            future investments to a different {productVariant}.
          </p>
          {renderInvestmentOptionsDetails()}
          <hr />

          <div className="color-text-dark text-small">
            {renderApexOrTelSwitchProcessDetail()}
            <p className="text-small">
              If you are unsure if you have transactions or updates pending,
              feel free to contact us on {COMPANY_PHONE_NUMBER}, email us at{' '}
              <a
                className="color-dusk"
                href="mailto:mailto:enquiries@fisherfunds.co.nz?subject=Online changing your investment enquiry"
              >
                enquiries@fisherfunds.co.nz
              </a>{' '}
              or visit{' '}
              <a
                className="color-dusk"
                href="https://fisherfunds.co.nz"
                target="_blank"
                rel="noopener noreferrer"
              >
                our website
              </a>{' '}
              and speak to us on live chat.
            </p>

            <hr />

            {(errors.pds || errors.tnch) && (
              <Notification className="mb-sm" type="error">
                {errors.tnch && (
                  <p>
                    Please authorise {productVariant} changes and acknowledge
                    the terms and conditions that apply.
                  </p>
                )}
                {errors.pds && (
                  <p>
                    Please confirm you've downloaded the Product Disclosure
                    Statement (PDS).
                  </p>
                )}
              </Notification>
            )}
            {productHasPDS && (
              <div className="flex-row-center">
                <div>
                  <Checkbox
                    id="pds"
                    name="pds"
                    {...{
                      ...register('pds', {
                        required: productHasPDS,
                      }),
                      ref: undefined,
                      inputRef: register('pds').ref,
                    }}
                  />
                </div>
                <div>
                  <label className="text-regular" htmlFor="pds">
                    I have downloaded a copy of the Product Disclosure Statement
                    (PDS).
                  </label>
                </div>
              </div>
            )}
            <div className="flex-row-center">
              <div>
                <Checkbox
                  id="tnch"
                  name="tnch"
                  {...{
                    ...register('tnch', { required: true }),
                    ref: undefined,
                    inputRef: register('tnch').ref,
                  }}
                />
              </div>
              <div>
                <label className="text-regular" htmlFor="tnch">
                  I authorise you to make the {productVariant} change requested
                  and acknowledge the{' '}
                  <Button
                    className="terms-and-conditions"
                    onClick={() => setShowTermsAndConditions(true)}
                    variant="link"
                  >
                    terms and conditions
                  </Button>{' '}
                  that apply.
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__footer-buttons mt-md">
          <Button
            className="mr-md"
            onClick={onCancel}
            variant="link"
            iconLeft={IconArrowLeft}
          >
            I want to make a change
          </Button>
          <Button type="submit">{switchToLabel}</Button>
        </div>
      </form>
      {showTermsAndConditions && renderTermsModal()}
    </>
  )
}

export default ReviewChange
