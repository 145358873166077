import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import {
  AmountInput,
  ControlledAmountInput,
  ControlledRadioMenu,
  InputWrapper,
  Switch,
} from 'shared'
import {
  WithdrawalFormFields,
  WithdrawalTypeEnum,
} from '../WithdrawFundsModalGeneric'
import './MFWithdrawForm.scss'
import CurrencyTotal from '../../currency/CurrencyTotal'
import { FormikContextType } from 'formik'
import { AccountFund } from '../../../redux/account-funds/account-funds.model'
import './MFWithdrawAmount.scss'

type MFWithdrawAmountProps = {
  form: FormikContextType<WithdrawalFormFields>
  funds: AccountFund[]
  minimumAccountBalance: number
}

export const MFWithdrawAmount = ({
  form,
  funds = [],
  minimumAccountBalance,
}: MFWithdrawAmountProps) => {
  const [showFundSelector, setShowFundSelector] = useState(
    form.values.withdrawalFunds?.length > 0
  )

  const withdrawalAmountError = useMemo(
    () =>
      form.touched.withdrawalAmount || form.touched.withdrawalFunds
        ? form.errors.withdrawalAmount
        : false,
    [
      form.touched.withdrawalAmount,
      form.touched.withdrawalFunds,
      form.errors.withdrawalAmount,
    ]
  )

  useEffect(() => {
    if (!showFundSelector) {
      return
    }

    const total = form.values.withdrawalFunds.reduce(
      (total, fund) => total + fund.amount,
      0
    )
    if (total !== 0 && !form.touched.withdrawalFunds) {
      form.setFieldTouched('withdrawalFunds')
    }
    if (total !== 0 && !form.touched.withdrawalAmount) {
      form.setFieldTouched('withdrawalAmount')
    }
    if (total !== form.values.withdrawalAmount) {
      form.setFieldValue('withdrawalAmount', total || '')
    }
  }, [showFundSelector, form.values.withdrawalFunds]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!showFundSelector) {
      form.setFieldValue('withdrawalAmount', '')
      form.setFieldValue('withdrawalFunds', [])
    }
  }, [showFundSelector]) // eslint-disable-line react-hooks/exhaustive-deps

  const isPartialWithdrawal =
    form.values.withdrawalType === WithdrawalTypeEnum.Partial

  return (
    <ControlledRadioMenu
      className="mf-withdraw-amount"
      direction="vertical"
      form={form}
      showErrorLabel={false}
      options={[
        {
          label: 'Partial Withdrawal',
          value: WithdrawalTypeEnum.Partial,
          subLabel: (
            <>
              {isPartialWithdrawal && (
                <>
                  <label htmlFor="partial-withdraw-amount">Enter amount</label>
                  <div
                    className={cn(
                      'input-amount--dollar-sign input-group mt-xs',
                      {
                        'input-amount--disabled': showFundSelector,
                        'input-amount--error': !!withdrawalAmountError,
                      }
                    )}
                  >
                    <ControlledAmountInput
                      className="input-group--no-styles input-amount--dollar-sign partial-withdraw-amount text-bold"
                      id="partial-withdraw-amount"
                      form={form}
                      name="withdrawalAmount"
                      labelSize="md"
                      disabled={showFundSelector}
                      showErrorLabel={false}
                    />
                    {(funds || []).length > 1 && (
                      <Switch
                        className="switch-funds-selector text-small p-2xs"
                        label="Specify the fund"
                        id="toggle-funds-selector"
                        defaultChecked={showFundSelector}
                        onChange={() => setShowFundSelector(!showFundSelector)}
                      />
                    )}
                  </div>

                  {showFundSelector && (
                    <ul className="fund-selector">
                      {(funds || []).map((fund, index) => {
                        const formValue =
                          form.values.withdrawalFunds?.[index]?.amount
                        const formError: string = (form.errors
                          .withdrawalFunds?.[index] as any)?.amount
                        const error =
                          formError ||
                          (formValue > fund.dollarBalance
                            ? `Amount must be lower than ${fund.dollarBalance}`
                            : '')
                        return (
                          <li className="fund-item" key={fund.assetCode}>
                            <div className="flex-column">
                              <span className="font-fund">
                                {fund.fundDisplayName}
                              </span>
                              <em className="color-text-dark">
                                <CurrencyTotal
                                  className="text-small"
                                  value={fund.dollarBalance}
                                />
                                <span className="ml-2xs">available</span>
                              </em>
                            </div>
                            <InputWrapper
                              error={error}
                              showErrorLabel={!!error}
                            >
                              <AmountInput
                                className="input-amount--dollar-sign"
                                name={`withdrawalFunds[${index}]`}
                                min={0}
                                max={fund.dollarBalance}
                                value={formValue}
                                error={!!error}
                                step="0.01"
                                onChange={({ currentTarget: { value } }) => {
                                  const shouldRemove = value === ''
                                  if (shouldRemove) {
                                    form.setFieldValue(
                                      'withdrawalFunds',
                                      form.values.withdrawalFunds.filter(
                                        (f) => f.assetCode !== fund.assetCode
                                      )
                                    )
                                  } else {
                                    form.setFieldValue(
                                      `withdrawalFunds[${index}]`,
                                      {
                                        assetCode: fund.assetCode,
                                        amount: Number(value),
                                      }
                                    )
                                  }
                                }}
                                onMax={() => {
                                  form.setFieldValue('withdrawalFunds', [
                                    {
                                      assetCode: fund.assetCode,
                                      amount: fund.dollarBalance,
                                    },
                                  ])
                                }}
                              />
                            </InputWrapper>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                  {!!withdrawalAmountError && (
                    <p className="input-wrapper__error-label my-sm">
                      {withdrawalAmountError}
                    </p>
                  )}
                  <em className="mt-xs mb-md text-small text-thin">
                    Note: to keep your account open you must leave a minimum of
                    ${minimumAccountBalance} in your account.
                  </em>
                </>
              )}
              <hr className="mt-0" />
            </>
          ),
        },
        {
          label: 'Withdraw my entire investment',
          value: WithdrawalTypeEnum.Full,
          subLabel: !isPartialWithdrawal && (
            <p className="my-0 text-small text-thin">
              <em>
                If you make a full withdrawal, your account will be closed. To
                keep your account open you must leave a minimum of $
                {minimumAccountBalance} in the account.
              </em>
            </p>
          ),
        },
      ]}
      name="withdrawalType"
    />
  )
}
