import { connect } from 'react-redux'
import cn from 'classnames'
import { Button, Card, formatWithDecimalPlaces, Notification } from 'shared'
import {
  WithdrawalFormFields,
  WithdrawalTypeEnum,
} from '../WithdrawFundsModalGeneric'
import './MFWithdrawReview.scss'
import WalletIcon from './../../../assets/images/wallet.svg'
import { Account } from '../../../redux/accounts/accounts.model'
import { NextStepArrow } from './NextStepArrow'
import { AppState } from '../../../redux/app-state'
import { UserState } from '../../../redux/user/user.model'

type FromRedux = {
  user: UserState
}

type MFWithdrawReviewProps = {
  account: Account
  values: WithdrawalFormFields
  onBack: () => void
  onConfirm: () => void
}

const MFWithdrawReview = ({
  user,
  account,
  values,
  onBack,
  onConfirm,
}: MFWithdrawReviewProps & FromRedux) => {
  const hasChosenFunds = values.withdrawalFunds?.length > 0
  return (
    <div className="mf-withdraw-review">
      <Card className={cn({ 'pb-xs': hasChosenFunds })}>
        <div className="flex-row align-center text-large color-midnight withdraw-type">
          <img
            className="wallet-icon"
            src={WalletIcon}
            aria-hidden="false"
            alt=""
          />
          <span className="text-bold ml-sm">
            {values.withdrawalType} withdrawal
          </span>
          {values.withdrawalType === WithdrawalTypeEnum.Partial && (
            <span className="text-bold ml-auto">
              ${formatWithDecimalPlaces(values.withdrawalAmount, 2)}
            </span>
          )}
        </div>
        {hasChosenFunds && (
          <>
            <hr className="mt-sm mb-0" />
            <ul className="funds-list">
              {(values.withdrawalFunds || []).map((fund) => (
                <li
                  className="flex-row align-center text-regular color-midnight py-xs"
                  key={fund.assetCode}
                >
                  <span className="text-bold">
                    {
                      account.funds.find((f) => f.assetCode === fund.assetCode)
                        .fundDisplayName
                    }
                  </span>
                  <span className="ml-auto">
                    ${formatWithDecimalPlaces(fund.amount, 2)}
                  </span>
                </li>
              ))}
            </ul>
          </>
        )}
      </Card>

      <NextStepArrow />

      <Card className="mb-lg">
        <span className="bank-account">
          {values.useExistingBankAccount
            ? 'Existing bank account'
            : values.bankAccountNumber}
        </span>
      </Card>

      <Notification className="mb-md">
        You've completed all the information needed to proceed. Please keep in
        mind that it will usually take four working days to receive your money.
      </Notification>

      <h6>Confirm your withdrawal</h6>
      <p>
        Are you sure you want to make this withdrawal request? Once you click
        confirm, you won't be able to cancel this request. A confirmation email
        will be sent to <strong>{user.email}</strong>
      </p>

      <div className="flex-row mt-lg">
        <Button variant="link" onClick={onBack}>
          Make a change
        </Button>
        <Button className="ml-auto" type="submit" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

export default connect(mapStateToProps)(MFWithdrawReview)
