import cn from 'classnames'
import ArrowImg from './../../../assets/images/next-step-dashed-arrow.svg'
import './NextStepArrow.scss'

type NextStepArrowProps = {
  className?: string
}

export const NextStepArrow = ({ className }: NextStepArrowProps) => (
  <div className={cn('next-step-arrow', { [`${className}`]: !!className })}>
    <img src={ArrowImg} aria-hidden="true" alt="" />
  </div>
)
