import cn from 'classnames'
import { FormikContextType } from 'formik'
import { BankAccountInput, ControlledInput, ControlledRadioMenu } from 'shared'
import { WithdrawalFormFields } from './WithdrawFundsModalGeneric'

type WithdrawBankAccountProps = {
  className?: string
  form: FormikContextType<WithdrawalFormFields>
}

export const WithdrawBankAccount = ({
  className,
  form,
}: WithdrawBankAccountProps) => {
  return (
    <div
      className={cn('withdraw-bank-account', { [`${className}`]: className })}
    >
      <h6>Bank account details</h6>
      <div className="form-container-section bank-details">
        <ControlledRadioMenu
          className="text-bold"
          direction="vertical"
          form={form}
          showErrorLabel={false}
          options={[
            {
              label:
                'Make the payment to the same bank account used for my most recent withdrawal',
              value: true,
              subLabel: <hr className="mt-0" />,
            },
            {
              label: 'Add a new bank account',
              value: false,
            },
          ]}
          name={'useExistingBankAccount'}
        />
        {!form.values.useExistingBankAccount && (
          <div className="mt-sm">
            <ControlledInput
              className="text-bold"
              form={form}
              name={'bankAccountName'}
              label={'Bank account name'}
              dark
              inputClass="flex-row-center mt-xs"
              placeholder={'e.g John Smith'}
              labelSize="md"
            />
            <BankAccountInput
              label="Account number"
              className="pb-md text-bold"
              value={form.values.bankAccountNumber}
              error={
                form.touched.bankAccountNumber
                  ? form.errors.bankAccountNumber
                  : undefined
              }
              onChange={(val) => form.setFieldValue('bankAccountNumber', val)}
              onBlur={() =>
                form.setFieldTouched('bankAccountNumber', true, true)
              }
              dark
            />
            <p className="text-small color-text-dark mt-0">
              <em>
                To protect the security of your account, we may call to verify
                your withdrawal request. To process your withdrawal we need to
                have proof of your bank account. If you have not made a previous
                withrawal, or if your bank account differs from your last
                withdrawal, please email a copy of your bank statement or a
                deposit slip to us at{' '}
                <a
                  className="contact-menu-item email-link"
                  href="mailto:enquiries@fisherfunds.co.nz"
                >
                  enquiries@fisherfunds.co.nz
                </a>
                .
              </em>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
