import { useFormik } from 'formik'
import numeral from 'numeral'
import {
  Button,
  ControlledAmountInput,
  ControlledRadioMenu,
  Link,
} from 'shared'
import './WithdrawFundsForm.scss'
import {
  WithdrawalFormFields,
  WithdrawalTypeEnum,
} from './WithdrawFundsModalGeneric'
import { WithdrawBankAccount } from './WithdrawBankAccount'
import { withdrawalFormSchema } from './WithdrawFundsForm.schema'
import { schemaValidation } from '../../utils'
import { Account } from '../../redux/accounts/accounts.model'
import {
  isInvestmentFundsAccount,
  isKiwiSaverTwoAccount,
} from '../../common/accounts-helper'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'

type WithdrawFundsFormProps = {
  account: Account
  handleSubmit: (values: WithdrawalFormFields) => void
  handleBack: () => void
  isDisable?: boolean
}

const WithdrawFundsForm = ({
  account,
  handleSubmit,
  handleBack,
  isDisable,
}: WithdrawFundsFormProps) => {
  const MINIMUM_ACCOUNT_BALANCE = isKiwiSaverTwoAccount(account, false)
    ? 500
    : 100
  const accountTotalMinusMinimumBalance = numeral(account?.accountTotal)
    .subtract(MINIMUM_ACCOUNT_BALANCE)
    .value()

  const initialValues: WithdrawalFormFields = {
    product: account.productExternalRef,
    accountId: account.accountID,
    withdrawalType: WithdrawalTypeEnum.Partial,
    withdrawalAmount: null,
    useExistingBankAccount: true,
    bankAccountName: null,
    bankAccountNumber: null,
  }
  const form = useFormik({
    initialValues: { ...initialValues },
    validate: schemaValidation<WithdrawalFormFields>(
      withdrawalFormSchema(accountTotalMinusMinimumBalance)
    ),
    onSubmit: () => {
      handleSubmit(form.values)
    },

    validateOnMount: true,
  })

  const isInvestmentFunds = isInvestmentFundsAccount(account)

  const handleFieldChange = () => {
    if (form.values.withdrawalType === WithdrawalTypeEnum.Full) {
      form.setFieldValue('withdrawalAmount', null)
    }
    if (form.values.useExistingBankAccount) {
      form.setFieldValue('bankAccountName', null)
      form.setFieldValue('bankAccountNumber', null)
    }
  }

  const isPartialWithdrawal =
    form.values.withdrawalType === WithdrawalTypeEnum.Partial

  return (
    <>
      <div className="form-container">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleFieldChange()
            form.handleSubmit()
          }}
        >
          <div className="form-container-section">
            <h6>Withdrawal type and amount </h6>
            <div className="form-container-section withdrawal">
              <ControlledRadioMenu
                className={`text-bold ml-md ${
                  isPartialWithdrawal ? '' : ' pb-md'
                }`}
                direction="vertical"
                form={form}
                options={[
                  {
                    label: 'Full Withdrawal',
                    value: WithdrawalTypeEnum.Full,
                    subLabel: !isPartialWithdrawal ? (
                      <div>
                        <p className="form-container-section-sub-label text-small text-thin">
                          <em>
                            {`If you make a full withdrawal, your account will be closed. To keep your account open you must leave a minimum of $${MINIMUM_ACCOUNT_BALANCE} in the account.`}
                          </em>
                        </p>
                        <hr />
                      </div>
                    ) : (
                      <hr className="mb-0" />
                    ),
                  },
                  {
                    label: 'Partial Withdrawal',
                    subLabel: isPartialWithdrawal && (
                      <div>
                        <p className="form-container-section-sub-label text-small text-thin ml-xl">
                          <em>
                            {`$${accountTotalMinusMinimumBalance}
                          available`}
                          </em>
                        </p>
                        <p className="form-container-section-sub-label text-small text-thin">
                          <em>
                            This excludes ${MINIMUM_ACCOUNT_BALANCE} which must
                            be left in your account for it to remain open.
                          </em>
                        </p>
                      </div>
                    ),
                    value: WithdrawalTypeEnum.Partial,
                  },
                ]}
                name={'withdrawalType'}
                disabled={isDisable}
              />
              {isPartialWithdrawal && (
                <div className="pb-sm mx-md">
                  <ControlledAmountInput
                    className="text-bold"
                    form={form}
                    name={'withdrawalAmount'}
                    label={'Withdrawal amount'}
                    dark
                    inputClass="flex-row-center mt-xs"
                    labelSize="md"
                    disabled={isDisable}
                  />
                </div>
              )}
            </div>
          </div>
          <WithdrawBankAccount
            className="form-container-section mt-md"
            form={form}
          />
          <div className="action-buttons my-md">
            <Button
              className="text-regular mx-md "
              variant="filled"
              color="primary"
              size={'sm'}
              type="submit"
              disabled={isDisable}
            >
              {isDisable ? <LoadingSpinner /> : 'Submit'}
            </Button>
            {handleBack && (
              <Button
                className="text-regular mx-md"
                variant="link"
                color="primary"
                size={'sm'}
                onClick={handleBack}
                disabled={isDisable}
              >
                Back
              </Button>
            )}
          </div>
        </form>
      </div>
      <div className="form-disclaimer">
        <p className="text-small color-text-light">
          1. Once we have all required information, payments are usually made
          within 5 working days
        </p>
        {isInvestmentFunds && (
          <p className="text-small color-text-light">
            2. <strong>Trusts and Entities</strong>: Online withdrawals are not
            currently available. To submit a withdrawal for an account owned by
            a Trust or Entity, please complete the{' '}
            <Link
              target="_blank"
              className="text-underline"
              to="https://assets.fisherfunds.co.nz/investment-funds-withdrawals-form"
              size="sm"
            >
              Fisher Funds Investment Funds Withdrawals Form
            </Link>
            .
          </p>
        )}
      </div>
    </>
  )
}

export default WithdrawFundsForm
