import cn from 'classnames'
import { ProductSlug } from 'shared'
import Modal, { ModalProps } from './Modal'
import './ProductModal.scss'

type ProductModalProps = {
  productSlug: ProductSlug
  className?: string
  shortBackground?: boolean
  children: any
} & ModalProps

export const ProductModal = ({
  productSlug,
  className,
  shortBackground = false,
  children,
  ...modalProps
}: ProductModalProps) => (
  <Modal
    className={cn(`product-modal product-modal--${productSlug}`, {
      [`${className}`]: !!className,
      'short-background': shortBackground,
    })}
    open
    {...modalProps}
  >
    {children}
  </Modal>
)
