import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import moment from 'moment'
import {
  AccountTransactionsRequestFailureAction,
  AccountTransactionsSetIsLoadingPDFAction,
  AccountTransactionsActions,
} from '../../redux/account-transactions/account-transactions.actions'
import { AppState } from '../../redux/app-state'
import { getAccountByID } from '../../common/accounts-helper'

import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import downloadFile from '../../common/downloads-helper'
import {
  FUTUREPLAN_TEL,
  KIWISAVER_ONE,
  LIFE_SAVER_TEL,
} from '../../common/product-variables'

const axios = require('axios')

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL

interface PDFDownloadProps {
  accountNumber: string
  productName: string
  productExternalRef: string
  authToken?: string
  currentAccount?: any
  userId?: string
  filters?: any
  isLoading?: boolean
  isLoadingPDF?: boolean
  numberOfTransactions?: number
  pdfRequestFailMessage?: any
  updateIsLoadingPDF?: any
}

export class PDFDownload extends React.Component<PDFDownloadProps> {
  constructor(props: PDFDownloadProps) {
    super(props)

    this.downloadPDF = this.downloadPDF.bind(this)
  }

  downloadPDF() {
    const { filters } = this.props

    let fileName = `${this.props.productName} ${this.props.accountNumber}_transactions_${filters['dateType']}`
    if (filters['dateType'] === 'custom') {
      if (filters['startDate']) {
        fileName += ' ' + filters['startDate'].format('DD-MM-YYYY')
      }

      if (filters['endDate']) {
        fileName += ' ' + filters['endDate'].format('DD-MM-YYYY')
      }
    }

    // utc
    var utc = moment.utc()
    fileName += '_' + utc

    // Stopping downloading twice
    if (this.props.isLoadingPDF) {
      return
    }

    // Set loading state
    this.props.updateIsLoadingPDF({
      isLoadingPDF: true,
    })

    // Remove any non digit characters from dates and add pdf extension
    fileName = fileName.trim().replace(/[\s\\/:*?"<>|.]/g, '_') + '.pdf'

    // Get account details
    const account = {
      mailingName: '',
      name: '',
      number: '',
    }

    // From current account array
    if (this.props.currentAccount) {
      account.mailingName = this.props.currentAccount.accountMailingName
      account.name = this.props.currentAccount.productName
      account.number = this.props.currentAccount.accountNumber
    }

    // Post data
    let postData = {
      filters: {
        dateType: filters.dateType,
        startDate: filters.startDate.format('YYYY-MM-DD'),
        endDate: filters.endDate.format('YYYY-MM-DD'),
      },
      account,
    }

    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.authToken,
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
      data: JSON.stringify(postData),
      url: `${ffmAppServerUrl}/api/users/${this.props.userId}/accounts/${this.props.accountNumber}/transactions/pdf`,
    }

    // Using axios library for pdf request instead of redux-observable
    //   to avoid saving all file content in redux state
    axios(options)
      .then((resp: any) => {
        let blob: Blob = new Blob([resp.data], { type: 'application/pdf' })
        downloadFile(blob, fileName)
      })
      .catch((error: any) => {
        this.props.pdfRequestFailMessage('Failed to download pdf.')
      })
      .finally(() => {
        // Set loading state
        this.props.updateIsLoadingPDF({
          isLoadingPDF: false,
        })
      })
  }

  render() {
    if (
      ![KIWISAVER_ONE, FUTUREPLAN_TEL, LIFE_SAVER_TEL].includes(
        this.props.productExternalRef
      )
    ) {
      return null
    }

    // Hide button if no transaction is available
    if (this.props.numberOfTransactions <= 0) {
      return null
    }

    return (
      <Button
        disableRipple
        variant="outlined"
        title={'Download as PDF'}
        onClick={this.downloadPDF}
        disabled={this.props.isLoadingPDF}
        startIcon={<GetAppIcon />}
        color="secondary"
      >
        PDF
      </Button>
    )
  }
}

const mapStateToProps = (state: AppState, props: PDFDownloadProps) => {
  return {
    authToken: state.authorisation.authorisationToken,
    filters: state.accountTransactions.filters,
    currentAccount: getAccountByID(
      props.accountNumber.toString(),
      state.accounts.accounts
    ),
    isLoading: state.accountTransactions.isLoadingAllTransactions,
    isLoadingPDF: state.accountTransactions.isLoadingPDF,
    numberOfTransactions: state.accountTransactions.transactions
      ? state.accountTransactions.transactions.length
      : null,
    userId: state.user.userid,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AccountTransactionsActions>
) => ({
  pdfRequestFailMessage: bindActionCreators(
    AccountTransactionsRequestFailureAction,
    dispatch
  ),
  updateIsLoadingPDF: bindActionCreators(
    AccountTransactionsSetIsLoadingPDFAction,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps, null)(PDFDownload)
