import { useState } from 'react'
import { ProductSlug } from 'shared'
import { WithdrawFundsModalProps } from '../WithdrawFundsModal'
import { WithdrawalFormFields } from '../WithdrawFundsModalGeneric'
import { MFWithdrawForm } from './MFWithdrawForm'
import MFWithdrawReview from './MFWithdrawReview'
import { ProductModal } from '../../modal/ProductModal'
import CurrencyTotal from '../../currency/CurrencyTotal'
import './MFWithdrawFundsModal.scss'

enum WITHDRAW_STEP {
  FORM = 'FORM',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
}

export const MFWithdrawFundsModal = (props: WithdrawFundsModalProps) => {
  const [currentStep, setCurrentStep] = useState(WITHDRAW_STEP.FORM)

  const [values, setValues] = useState<WithdrawalFormFields>()
  const [isLoading, setIsLoading] = useState(false)

  const { account, onClose } = props

  const renderStep = () => {
    if (currentStep === WITHDRAW_STEP.FORM) {
      return (
        <MFWithdrawForm
          onCancel={onClose}
          onSubmit={(values) => {
            setValues(values)
            setCurrentStep(WITHDRAW_STEP.REVIEW)
          }}
          values={values}
          isDisable={isLoading}
          {...props}
        />
      )
    }

    // if (currentStep === WITHDRAW_STEP.REVIEW) {
    return (
      <MFWithdrawReview
        account={account}
        values={values}
        onBack={() => setCurrentStep(WITHDRAW_STEP.FORM)}
        onConfirm={() => {
          setIsLoading(true)
          // TODO: Call API
        }}
      />
    )

    // }
    // return <MFWithdrawForm />
  }

  return (
    <ProductModal
      className="managed-funds-withdraw-modal"
      shortBackground={currentStep === WITHDRAW_STEP.REVIEW}
      productSlug={ProductSlug.FFMF}
      title="Withdraw funds"
      subtitle={`Managed Funds - ${account.accountNumber}`}
      onClose={onClose}
    >
      <CurrencyTotal
        className="color-white mb-md"
        value={account.accountTotal}
        size="lg"
      />
      {renderStep()}
    </ProductModal>
  )
}
