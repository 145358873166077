import { useMemo } from 'react'
import numeral from 'numeral'
import { useFormik } from 'formik'
import { Button, Card, schemaValidation } from 'shared'
import {
  WithdrawalFormFields,
  WithdrawalTypeEnum,
} from '../WithdrawFundsModalGeneric'
import { Account } from '../../../redux/accounts/accounts.model'
import { withdrawalFormSchema } from '../WithdrawFundsForm.schema'
import './MFWithdrawForm.scss'
import { NextStepArrow } from './NextStepArrow'
import { MFWithdrawAmount } from './MFWithdrawAmount'
import { WithdrawBankAccount } from '../WithdrawBankAccount'

type MFWithdrawFormProps = {
  account: Account
  onCancel: () => void
  onSubmit: (values: WithdrawalFormFields) => void
  isDisable?: boolean
  values?: WithdrawalFormFields
}

const MINIMUM_ACCOUNT_BALANCE = 1000

export const MFWithdrawForm = ({
  account,
  onCancel,
  onSubmit,
  isDisable = false,
  values,
}: MFWithdrawFormProps) => {
  const accountTotalMinusMinimumBalance = useMemo(() => {
    return numeral(account?.accountTotal)
      .subtract(MINIMUM_ACCOUNT_BALANCE)
      .value()
  }, [account])

  const initialValues: WithdrawalFormFields = {
    product: account.productExternalRef,
    accountId: account.accountID,
    withdrawalType: values?.withdrawalType || WithdrawalTypeEnum.Partial,
    withdrawalAmount: values?.withdrawalAmount || undefined,
    withdrawalFunds: values?.withdrawalFunds || [],
    useExistingBankAccount: values?.useExistingBankAccount || true,
    bankAccountName: values?.bankAccountName || null,
    bankAccountNumber: values?.bankAccountNumber || null,
  }

  const form = useFormik({
    initialValues: { ...initialValues },
    validate: schemaValidation<WithdrawalFormFields>(
      withdrawalFormSchema(accountTotalMinusMinimumBalance)
    ),
    onSubmit: () => {
      if (form.values.withdrawalType === WithdrawalTypeEnum.Full) {
        form.setFieldValue('withdrawalAmount', null)
        form.setFieldValue('withdrawalFunds', null)
      }
      if (form.values.useExistingBankAccount) {
        form.setFieldValue('bankAccountName', null)
        form.setFieldValue('bankAccountNumber', null)
      }
      onSubmit(form.values)
    },
    validateOnMount: true,
  })

  return (
    <form
      className="mf-withdraw-form"
      onSubmit={(e) => {
        e.preventDefault()
        form.handleSubmit()
      }}
    >
      <Card>
        <MFWithdrawAmount
          form={form}
          funds={account.funds}
          minimumAccountBalance={MINIMUM_ACCOUNT_BALANCE}
        />
      </Card>

      <NextStepArrow className="mx-auto" />

      <Card>
        <WithdrawBankAccount form={form} />
      </Card>

      <div className="flex-row mt-lg">
        <Button variant="link" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="ml-auto"
          type="submit"
          disabled={isDisable || !form.isValid}
        >
          Continue
        </Button>
      </div>
    </form>
  )
}
